#feature {
  .root {
    position: relative;
    padding-top: $spacing5;
    @include breakpoints-up(sm) {
      padding-top: $spacing10;
    }
    .btn {
      width: 160px;
    }
  }

  .decoration-bg {
    position: absolute;
    width: 1280px;
    height: 100%;
    @include left(-10px);
    top: 180px;
    svg {
      width: 100%;
      height: 1700px;
      @include use-theme(fill, $palette-primary-light, $palette-primary-dark);
      opacity: 0.2;
      transform: scale(1.3);
      @media only screen and (min-width: 1400px) {
        transform: scale(2.5, 1);
      }
      @include breakpoints-up(xl) {
        display: none
      }
      @include breakpoints-down(xs) {
        transform: scale(0.5);
        transform-origin: center left
      }
    }
  }

  .item {
    position: relative;
    min-height: 320px;
    margin-bottom: $spacing20;
    .desc {
      position: relative;
      z-index: 60
    }
    @include breakpoints-down(md) {
      margin-bottom: $spacing12
    }
    @include breakpoints-down(sm) {
      text-align: center
    }
    h6 {
      margin-bottom: $spacing5
    }
  }

  .screen-wrap {
    padding: $spacing4;
    @include breakpoints-down(xs) {
      padding: 0;
      margin-top: $spacing3
    }
    position: relative;
    > img {
      position: absolute;
    }
  }

  .plant1 {
    width: 120%;
    top: -50px;
    left: -50px;
    @include breakpoints-down(xs) {
      top: -20px;
      left: -20px;
    }
  }

  .plant2 {
    width: 120%;
    bottom: -20px;
    left: -40px;
    @include breakpoints-down(xs) {
      bottom: 0;
      left: -20px;
    }
  }

  .plant3 {
    width: 110%;
    top: -20px;
    left: -20px;
    @include breakpoints-down(xs) {
      top: 10px;
      left: -10px;
    }
  }

  .illustration {
    position: relative;
    figure {
      border-radius: $rounded-big;
      overflow: hidden;
      position: relative;
      @include shadow8;
      img {
        width: 100%;
        display: block
      }
    }
    &:before {
      content: "";
      border-radius: $rounded-big;
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      opacity: 0.5;
      @include use-theme(background, linear-gradient(120deg, $palette-primary-light, $palette-secondary-light), linear-gradient(120deg, $palette-primary-dark, $palette-secondary-dark));
      position: absolute;
      top: -16px;
      left: -16px;
    }
  }

  .screen-center {
    max-width: 700px;
    margin: 0 auto;
    figure {
      display: block;
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
  }
}