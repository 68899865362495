.news-event-card {
  display: flex;
  [dir="rtl"] & {
    direction: rtl;
  }
  @include breakpoints-down(xs) {
    flex-direction: column
  }
  p {
    margin-bottom: 0
  }
  figure {
    overflow: hidden;
    border-radius: $rounded-small;
    margin: 0;
    @include margin-right($spacing2);
    width: 120px;
    height: 140px;
    @include breakpoints-down(xs) {
      width: auto;
      height: auto;
      max-height: 200px;
      margin: spacing(2, 1)
    }
    img {
      max-height: 100%;
      min-width: 100%;
      @include margin-left(50%);
      [dir="rtl"] & {
        transform: translateX(50%);
      }
      [dir="ltr"] & {
        transform: translateX(-50%);
      }
      @include breakpoints-down(xs) {
        width: 100%;
        height: auto
      }
    }
  }
  .desc {
    flex: 1;
  }
  .text {
    padding: spacing(0, 1.5);
    @include breakpoints-up(md) {
      max-width: 400px;
    }
  }
  .type {
    font-weight: $font-bold;
    text-transform: uppercase;
    @include use-theme(color, $palette-primary-dark, $palette-primary-light)
  }
  .btn {
    margin-top: $spacing2;
    @include margin-left(-4px);
    @include use-theme(color, $palette-primary-main, $palette-primary-light)
  }
}
