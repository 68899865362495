#blog {
  .root {
    position: relative;
    z-index: 56;
    max-width: 1140px;
    margin: 0 auto;
  }

  .carousel {
    padding-bottom: $spacing7;
    .slick-dots {
      bottom: $spacing7 * -1;
      li {
        width: 15px;
        height: 10px;
        border: 1px solid;
        @include use-theme(border-color, $light-text-disabled, $dark-text-disabled);
        border-radius: 15px;
        opacity: 0.7;
        transition: all 0.5s ease-out;
        &.slick-active {
          @include use-theme(background, $palette-primary-main, $palette-primary-light);
          @include use-theme(border-color, $palette-primary-main, $palette-primary-light);
          width: 30px;
        }
      }
      li button:before {
        display: none
      }
    }
  }

  .item {
    padding: $spacing1
  }
}