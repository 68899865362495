#integration {
  .root {
    .container .row{
      position: relative;
    }
  }

  .decoration-bg {
    position: absolute;
    width: 1280px;
    height: 100%;
    @include left(-10px);
    top: 600px;
    svg {
      width: 100%;
      height: 1300px;
      @include use-theme(fill, $palette-secondary-light, $palette-secondary-dark);
      opacity: 0.2;
      transform: scale(1.2);
      @media only screen and (min-width: 1400px) {
        transform: scale(2.5, 1);
      }
      @include breakpoints-up(xl) {
        display: none
      }
      @include breakpoints-down(xs) {
        transform: scale(0.5);
        transform-origin: center left
      }
    }
  }

  .illustration {
    img {
      display: block;
      max-width: 100%;
      margin-top: $spacing2;
      margin-bottom: $spacing2;
      @include breakpoints-up(sm) {
        margin-top: $spacing4;
        margin-bottom: $spacing4;
        height: 240px;
      }
    }
    &.left-illustration {
      @include margin-left(auto)
    }
    &.right-illustration {
      @include margin-right(auto)
    }
  }

  .list {
    @include breakpoints-up(sm) {
      margin-top: $spacing10;
    }
    ul {
      padding: 0;
      li {
        margin-bottom: 20px;
        list-style: none;
        font-size: 24px;
        @include breakpoints-down(sm) {
          font-size: 16px;
        }
        .icon {
          border-radius: 50%;
          padding: 6px;
          @include breakpoints-down(sm) {
            font-size: 14px;
          }
        }
      }
    }
    h3 {
      font-weight: $font-bold;
      margin-bottom: $spacing1;
    }
    &-primary {
      .icon {
        @include use-theme(background, $palette-primary-light, $palette-primary-dark);
        color: $palette-primary-main;
        margin-right: $spacing3;
        @include breakpoints-down(xs) {
          @include margin-right($spacing2);
        }
      }
    }
    &-secondary {
      li {
        @include breakpoints-up(sm) {
          @include text-align(right)
        }
      }
      .icon {
        @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
        color: $palette-secondary-main;
        @include breakpoints-up(sm) {
          @include margin-left($spacing3);
        }
        @include breakpoints-down(xs) {
          @include float(left);
          @include margin-right($spacing2);
        }
      }
    }
  }


}