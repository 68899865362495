$wave-deco-light: '../images/saas2/deco-wave-light.png';
$wave-deco-dark: '../images/saas2/deco-wave-dark.png';

@mixin deco-cta {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: -30px;
  left: 0;
  opacity: 0.5;
  background-size: 1000px 150px;
  background-repeat: repeat-x;
  .theme--dark & {
    background-image: url($wave-deco-dark)
  }
  .theme--light & {
    background-image: url($wave-deco-light)
  }
}

.call-to-action {
  .root {
    position: relative;
    z-index: 60;
    p {
      margin: 0
    }
  }

  .deco {
    @include deco-cta;
    background-position: -160px 50px;
    transform: scale(1.2, 0.7);
    @include breakpoints-down(xs) {
      bottom: -60px;
    }
    &:before {
      content: "";
      transform: scale(1.2, 1);
      @include deco-cta;
      background-position: 250px 10px;
    }
  }
  .button.btn {
    margin: 0 auto;
    height: 52px;
    line-height: 52px;
    font-size: 18px;
    width: 100%;
    @include breakpoints-up(sm) {
      width: 360px;
    }
  }

  .paper {
    position: relative;
    overflow: hidden;
    padding: $spacing4;
    text-align: center;
    &.card {
      @include use-theme(background, $palette-primary-light, $palette-primary-dark);
      @include use-theme(color, $palette-primary-dark, $palette-primary-light);
      .theme--dark & {
        box-shadow: 0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)
      }
      .theme--light & {
        box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
      }
    }
    @include breakpoints-down(sm) {
      text-align: center
    }
    @include breakpoints-up(lg) {
      padding: spacing(4, 10);
      margin: spacing(0, 4);
    }
    @include breakpoints-up(sm) {
      border-radius: $rounded-big !important;
    }
    @include breakpoints-down(sm) {
      margin-bottom: $spacing5;
    }
    @include breakpoints-down(xs) {
      padding: spacing(4, 2);
      text-align: center
    }
    h4 {
      font-weight: 700;
      @include breakpoints-down(xs) {
        font-size: 28px !important;
        margin-bottom: $spacing2
      }
    }
    p {
      font-size: 24px;
      @include breakpoints-down(sm) {
        margin-bottom: $spacing5
      }
      @include breakpoints-down(xs) {
        font-size: 18px;
        margin-bottom: $spacing3
      }
    }
  }
}
