$wave-deco-light: '../images/saas2/deco-wave-light.png';
$wave-deco-dark: '../images/saas2/deco-wave-dark.png';

.footer-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  &:before {
    content: "";
    position: absolute;
    background-image: linear-gradient(120deg, $palette-primary-main, $palette-secondary-main);
    opacity: 0.15;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 140px;
    left: 0;
    transform: scaleY(-1);
    transform-origin: top center;
    background-repeat: repeat-x;
    background-position: -210px 0px;
    background-size: 1790px 140px;
    .theme--dark & {
      background-image: url($wave-deco-dark)
    }
    .theme--light & {
      background-image: url($wave-deco-light)
    }
  }

  .wave-cover {
    z-index: 10;
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: -16px;
    background-position: -200px bottom;
    background-size: 1670px 100px;
    background-repeat: repeat-x;
    .theme--dark & {
      background-image: url($wave-deco-dark)
    }
    .theme--light & {
      background-image: url($wave-deco-light)
    }
    @media only screen and (min-width: 1400px) { 
      display: none;
    }
  }

  .deco-wrap {
    z-index: 10;
    width: 100%;
    height: 100%;
    @media only screen and (min-width: 1400px) { 
      display: none;
    }
  }

  .deco {
    position: absolute;
    bottom: 0;
    svg {
      width: 2000px;
    }
    &.top {
      bottom: -30px;
      left: 0;
      height: 240px;
      width: 100%;
      opacity: 0.5;
      .wave-cover {
        background-position: 430px 110px;
        background-size: 1900px 110px;
      }
    }
    &.bottom {
      left: -370px;
      bottom: -30px;
      opacity: 0.4;
      svg {
        @include use-theme(fill, $palette-primary-light, $palette-primary-dark);
      }
    }
  }
}
