.company-logo
  .root {
    display: flex;
    @include breakpoints-up(sm) {
      justify-content: center;
    }
    @include breakpoints-down(xs) {
      overflow-x: auto
    }
    img {
      height: 64px;
      margin: $spacing4;
    }
  }
