.chat {
  .chat-button {
    z-index: 20;
    position: fixed;
    bottom: 40px;
    right: 40px;
    @include text-align(right);
    font-size: 32px;
    &.btn-floating {
      overflow: visible;
      @include shade;
      @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
    }
    i {
      position: relative;
      top: 3px;
      @include use-theme(color, $palette-primary-dark, $palette-primary-light);
    }
    .indicator {
      position: absolute;
      top: 4px;
      @include left(2px)
    }
  }

  .panel {
    position: fixed;
    bottom: 40px;
    right: 40px;
    @include text-align(right);
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    @include text-align(left);
    transform: scale(0.9);
    transition: all 0.2s cubic-bezier(0.01, 0.65, 0.3, 0.9);
    @include palette-background-paper;
    @include shade;
    border-radius: $rounded-medium;
    overflow: hidden;
    header {
      display: flex;
      padding: spacing(2, 2, 0.5);
      @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
      h3 {
        flex: 1;
        font-size: 16px;
        font-weight: $font-bold;
        margin: 0;
        line-height: 28px;
      }
    }
    &.show {
      transform: scale(1);
      visibility: visible;
      z-index: 100;
      opacity: 1;
    }
  }

  .close {
    font-size: 24px;
    position: relative;
    top: -4px;
    i {
      position: relative;
      top: 0;
    }
  }

  .indicator {
    @include margin-right(4px);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-block;
  }

  .online {
    background: #07D517
  }

  .offline {
    background: #b7b7b7
  }

  .chat-room {
    padding: $spacing2;
    position: relative;
    height: 300px;
    width: 300px;
    overflow: auto;
    ul {
      padding: 0;
      li {
        padding: 0;
        display: flex;
        position: relative;
        margin-bottom: $spacing4;
      }
    }
  }

  .avatar {
    img {
      width: 38px;
      height: 38px;
    }
  }

  .talk {
    position: relative;
    border-radius: $rounded-small;
    border: 2px solid $palette-secondary-main;
    padding: spacing(1, 1.5);
    @include margin-left($spacing2)
  }

  .from {
    border: none;
    background: $palette-primary-main;
    color: $palette-common-white;
    &:before {
      position: absolute;
      top: 5px;
      content: "";
      @include left(-5px);
      border-top: 5px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 5px solid;
      @include use-theme(border-right-color, $palette-primary-light, $palette-primary-dark);
    }
  }


  .form {
    display: flex;
    align-items: center;
    margin: spacing(0, 2);
    .input-field {
      flex: 1
    }
  }

  .input {
    flex: 1
  }

  .send {
    @include margin-left($spacing2);
    font-size: 22px;
    text-align: center;
    box-shadow: none;
    @include use-theme(background-color, $palette-secondary-light, $palette-secondary-dark);
    span {
      @include use-theme(color, $palette-secondary-dark, $palette-secondary-light);
      @include margin-left(4px);
      @include rtl-flip;
    }
  }
}
