.counter-wrap {
  position: relative;

  .counter-item {
    p {
      display: flex;
      @include palette-text-secondary; 
      align-items: center;
      justify-content: center;
      margin: 0;
      i {
       font-size: 40px;
        @include margin-right($spacing2);
        @include breakpoints-down(xs) {
          font-size: 24px;
          @include margin-right($spacing1)
        }
      }
    }
    @include breakpoints-up(md) {
      display: flex
    }
    align-items: center;
    justify-content: center;
    .text {
      text-align: center;
      h3 {
        position: relative;
        .theme--dark & {
          color: $palette-primary-light
        }
        .theme--light & {
          color: $palette-primary-dark
        }
        padding-bottom: $spacing2
      }
    }
  }

  .counter-inner {
    .col {
      @include breakpoints-down(xs) {
        flex-basis: auto;
      }
    }
    > div {
      position: relative;
      &:after {
        content: "";
        @include border-left(2px solid);
        border-color: $palette-primary-dark;
        opacity: 0.2;
        height: 90px;
        position: absolute;
        @include right(0);
        top: 30px;
        @include breakpoints-down(xs) {
          display: none
        }
      }
    }
    > div:last-child {
      &:after {
        display: none
      }
    }
  }
}
