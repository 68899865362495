.left-title {
  @include text-align(left);
  &:after {
    @include left(0);
  }
  @include breakpoints-down(sm) {
    text-align: center !important;
    &:after {
      @include left(50%);
      @include margin-left(-35px);
    }
  }
}

.right-title {
  @include text-align(right);
  &:after {
    @include right(0);
  }
  @include breakpoints-down(sm) {
    text-align: center !important;
    &:after {
      @include left(50%);
      @include margin-left(-35px);
    }
  }
}

.center-title {
  text-align: center;
  &:after {
    @include left(50%);
    @include margin-left(-35px);
  }
}

.title-main {
  display: block;
  position: relative;
  margin-bottom: $spacing9;
  h4 {
    font-size: 36px;
    line-height: 56px;
    font-weight: $font-bold;
    @include breakpoints-down(md) {
      font-size: 32px;
      line-height: 48px;
    }
    @include breakpoints-down(xs) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  &:after {
    content: "";
    width: 70px;
    height: 12px;
    bottom: -32px;
    border-radius: 12px;
    @include use-theme(background, linear-gradient(120deg, $palette-primary-light, $palette-secondary-light), linear-gradient(120deg, $palette-primary-dark, $palette-secondary-dark));
    position: absolute
  }
  & strong {
    @include palette-text-primary
  }
}

.title-secondary {
  display: block;
  position: relative;
  margin-bottom: $spacing12;
  text-transform: capitalize;
  @include breakpoints-down(xs) {
    margin-bottom: $spacing10
  }
  h3 {
    @include palette-text-primary;
    font-size: 36px;
    line-height: 56px;
    font-weight: $font-bold;
    letter-spacing: 1px;
    @include breakpoints-down(md) {
      font-size: 32px;
      line-height: 48px;
    }
    @include breakpoints-down(xs) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  &:after {
    content: "";
    width: 70px;
    height: 12px;
    bottom: $spacing4 * -1;
    border-radius: 12px;
    background: $palette-primary-main;
    position: absolute
  }
  strong {
    @include palette-text-primary
  }
}