.bg-deco {
  .decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    @include left(0);
    overflow: hidden;
    clip: rect(0, auto, auto, 0);
    svg {
      @include use-theme(fill, $palette-common-white, $palette-common-black);
      opacity: 0.15;
      position: fixed;
      top: 0;
    }
  }

  .left-deco {
    left: -400px;
    right: auto;
    width: 1200px;
    height: 1500px;
    transform-origin: top left;
    @include breakpoints-up(md) {
      transform: scale(0.8)
    }
  }

  .right-deco {
    left: auto;
    right: 0;
    height: 1500px;
    transform-origin: top right;
    @include breakpoints-up(md) {
      transform: scale(0.8)
    }
  }
}